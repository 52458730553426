import React from 'react';

import { CookiesPage } from '../modules/cookies-page/components/CookiesPage';
import { Layout } from '../modules/ui';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const Cookies = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo pageId={Constants.pages.COOKIES} />
            <Layout footerRef={footerRef}>
                <CookiesPage />
            </Layout>
        </>
    );
};

export default Cookies;
